/**
 * Returns full regex match, or null if no match
 * @param {String} value, the string which may match *pattern*
 * @param {String} pattern, regex string
 * @returns {String?}
 */
function getFullMatch(value, pattern) {
  const matches = value.match(pattern);
  return (matches && matches.length) ? matches[0] : null;
}

export default {
  /**
   * Returns domain based on *hostname*.
   * Prefers buzzfeed.com subdomain.
   * Returns generic domain if not a buzzfeed dev|stage|www subdomain.
   * @param hostname {String}
   * @returns {String}
   */
  getBuzzfeedSubdomainOrWildcard(hostname) {
    const subdomainMatch = getFullMatch(hostname, '(dev|stage|www).(buzzfeed|buzzfeednews).(com|io)$');
    if (subdomainMatch) {
      return subdomainMatch;
    }

    return getFullMatch(hostname, '.?[a-z]+.[a-z]+$');
  },

  /**
   * Gets cookie value by name.
   * @param {String} name - cookie name.
   * @param {Object} [defaultValue] - value to return if cookie is not presented.
   * @returns {?Object} - value of the specified cookie or default.
   */
  get(name, defaultValue = null) {
    const nameEQ = `${name}=`;

    if (typeof document === 'undefined') {
      return defaultValue;
    }

    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return defaultValue;
  },

  /**
   * Creates cookie with specified properties.
   * @param {Object} args - options for creating a cookie.
   * @param {String} args.name - cookie name.
   * @param {Object} args.value - cookie value.
   * @param {Number} [args.days] - number of days until cookie expires.
   * @param {String} args.domain - the domain to set cookie on. NOTE: It's important
   *   that you set this value or it's likely that you'll end up with cookies
   *   restricted to a subdomain you do not want.
   * @returns {String} document.cookie to be set
   */
  set({ name, value, days, domain }) {
    let exp = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      exp = `; expires=${date.toGMTString()}`;
    }
    let domainString = '';
    if (domain !== undefined) {
      domainString = `; domain=${domain}`;
    }
    return document.cookie = `${name}=${value}${exp}${domainString}; path=/`;
  },

  /**
   * Removes cookie by name.
   * Sets expiration date on yesterday.
   * @param {String} name - cookie name.
   * @param {String} domain - the hostname of the cookie.
   * @returns {void}
   */
  remove(name, domain) {
    return this.set({
      name,
      value: '',
      days: -1,
      domain: domain
    });
  }
};
